<template>
    <side-panel class="user-details">
        <div class="position-sticky title d-flex flex-row justify-content-between pt-3 px-3 pb-2">
            <div class="mr-1">
                <router-link class="btn btn-outline-dark border-0 rounded-lg"
                             v-bind:to="backTo">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </router-link>
            </div>
            <div class="flex-grow-1">
                <h3 class="font-weight-bolder text-right mb-1">
                    {{ name }} <span v-if="nickname">a.k.a. {{ nickname }}</span>
                </h3>
                <h5 class="text-right mb-1">
                    (+{{ countryCode }} {{ phone }})
                </h5>
                <p class="text-dark text-right mb-0">
                    <strong class="text-uppercase">{{ role }}</strong> joined <strong>{{ timeCreated }}</strong>
                </p>
            </div>
        </div>
        <div class="px-3 pb-3">
            <h5 class="text-right">
                <span class="badge badge-success badge-pill mb-1 mr-1"
                      v-if="user && user.verified">
                    <font-awesome-icon icon="shield-check"></font-awesome-icon> User Verified
                </span>
                <span class="badge badge-success badge-pill mb-1 mr-1"
                      v-if="inRequiredGroups">
                  <font-awesome-icon icon="comment-alt-check"></font-awesome-icon>In required groups
                </span>
                <span class="badge badge-danger badge-pill mb-1 mr-1"
                      v-else>
                 <font-awesome-icon icon="comment-alt-times"></font-awesome-icon> Not in required groups
                </span>
            </h5>
        </div>
        <div class="details px-3 pb-3">
            <div class="row">
                <div class="col-md-6 px-2 mb-3">
                    <info-card class="gender-card text-white"
                               v-bind:class="gender">
                        <template v-slot:logo>
                            <font-awesome-icon class="logo"
                                               icon="venus"
                                               v-if="gender === 'female'">
                            </font-awesome-icon>
                            <font-awesome-icon class="logo"
                                               icon="mars"
                                               v-if="gender === 'male'">
                            </font-awesome-icon>
                        </template>
                        <h6 class="font-weight-normal text-uppercase mb-1">
                            Gender
                        </h6>
                        <h2 class="font-weight-bolder text-nowrap mb-0">
                            {{ gender === "male" ? "Male" : "Female" }}
                        </h2>
                    </info-card>
                </div>
                <div class="col-md-6 px-2 mb-3">
                    <info-card class="age-group-card">
                        <template v-slot:logo>
                            <font-awesome-icon class="logo"
                                               icon="birthday-cake">
                            </font-awesome-icon>
                        </template>
                        <h6 class="font-weight-normal text-uppercase mb-1">
                            Age Group
                        </h6>
                        <h2 class="font-weight-bolder mb-0">
                            <span v-if="ageGroup === '>=41'">
                                &#62;40
                            </span>
                            <span v-else-if="ageGroup === '<=15'">
                                &#60;16
                            </span>
                            <span v-else>
                                {{ ageGroup }}
                            </span>
                        </h2>
                    </info-card>
                </div>
                <div class="col-12 px-2 mb-3">
                    <info-card class="location-card text-white">
                        <template v-slot:logo>
                            <font-awesome-icon class="logo"
                                               icon="globe">
                            </font-awesome-icon>
                        </template>
                        <h6 class="font-weight-normal text-uppercase mb-1">
                            Location
                        </h6>
                        <h2 class="font-weight-bolder mb-0">
                            🇭🇰 Hong Kong
                        </h2>
                    </info-card>
                </div>
                <div class="col-12 px-2 mb-3"
                     v-if="introduction && introduction !== 'NA'">
                    <info-card class="intro-card text-white">
                        <template v-slot:logo>
                            <font-awesome-icon class="logo"
                                               icon="comment-smile">
                            </font-awesome-icon>
                        </template>
                        <h6 class="font-weight-normal text-uppercase mb-1">
                            Intro
                        </h6>
                        <p class="introduction mb-0">
                            {{ introduction }}
                        </p>
                    </info-card>
                </div>
                <div class="col-12 px-2 mb-3"
                     v-if="referrer">
                    <info-card class="referrer-card text-white">
                        <template v-slot:logo>
                            <font-awesome-icon class="logo"
                                               icon="people-arrows">
                            </font-awesome-icon>
                        </template>
                        <h6 class="font-weight-normal text-uppercase mb-1">
                            Referrer
                        </h6>
                        <h2 class="font-weight-bolder mb-0">
                            {{ referrer }}
                        </h2>
                    </info-card>
                </div>
            </div>
            <whats-app-card class="mb-3"
                            v-bind:name="name"
                            v-bind:country-code="countryCode"
                            v-bind:phone="phone"
                            v-bind:account="account">
            </whats-app-card>
        </div>

        <div class="position-sticky actions p-3 d-flex justify-content-between">
            <button class="btn btn-block btn-warning shadow-sm rounded-pill m-0 mr-1"
                    v-bind:class="{ 'btn-loading': isEditing }"
                    v-on:click="isEditUserModalOpened = true">
                ✏️ Update
            </button>
            <button class="btn btn-block btn-danger shadow-sm rounded-pill m-0 ml-1"
                    v-bind:class="{ 'btn-loading': isEditing }"
                    v-on:click="isReauthenticateModalOpened = true">
                🗑️ Delete
            </button>
        </div>

        <modal-user editing
                    v-if="adminRole === 'admin'"
                    v-bind:user="user"
                    v-model="isEditUserModalOpened">
        </modal-user>
        <modal-re-authenticate challenge-type="password"
                               v-if="adminRole === 'admin'"
                               v-bind:email="adminEmail"
                               v-bind:callback="openDeleteUserModal"
                               v-model="isReauthenticateModalOpened">
            <p>You are conducting a sensitive action. Please authenticate yourself. </p>
        </modal-re-authenticate>
        <modal-delete-confirm field="name"
                              v-if="adminRole === 'admin'"
                              v-bind:target="user"
                              v-bind:callback="deleteUser"
                              v-model="isDeleteUserModalOpened">
        </modal-delete-confirm>
    </side-panel>
</template>

<script>
import mongoObjectIdToDate from "@/utilities/mongoObjectIdToDate";
import { formatDistanceToNow, parseISO, } from "date-fns";
import { VBTooltip, } from "bootstrap-vue";
import SidePanel from "../SidePanel";
import WhatsAppCard from "./UserDetails/WhatsAppCard";
import InfoCard from "./UserDetails/InfoCard";
import ModalUser from "./ModalUser";
import ModalReAuthenticate from "../modals/ModalReAuthenticate";
import ModalDeleteConfirm from "../modals/ModalDeleteConfirm";
export default {
    name: "UserDetails",
    components: {
        ModalDeleteConfirm,
        ModalReAuthenticate,
        ModalUser,
        InfoCard,
        WhatsAppCard,
        SidePanel,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    props: {
        "userId": {
            type: String,
        },
    },
    data () {
        return {
            isLoading: true,
            isEditing: false,

            isReauthenticateModalOpened: false,
            isEditUserModalOpened: false,
            isDeleteUserModalOpened: false,
        };
    },
    computed: {
        backTo () {
            const path = this.$route.path.split("/");
            path.pop();
            return path.join("/");
        },
        adminRole () {
            return this.$store.getters["admin/info"].role;
        },
        adminEmail () {
            return this.$store.getters["admin/info"].email;
        },
        user () {
            return this.$store.getters["users/itemById"](this.userId);
        },
        name () {
            return this.user?.name ?? "";
        },
        nickname () {
            return this.user?.profile?.nickname ?? "";
        },
        role () {
            return this.user?.role ?? "";
        },
        countryCode () {
            return this.user?.countryCode ?? "";
        },
        phone () {
            return this.user?.phone ?? "";
        },
        timeCreated () {
            if (this.user) {
                if (this.user.createdAt) {
                    return formatDistanceToNow(
                        parseISO(this.user.createdAt),
                        {
                            "addSuffix": true
                        }
                    );
                }
                return formatDistanceToNow(
                    parseISO(mongoObjectIdToDate(this.user._id)),
                    { "addSuffix": true, }
                );
            }

            return "";
        },
        gender () {
            return this.user?.profile?.gender ?? "";
        },
        ageGroup () {
            return this.user?.profile?.ageGroup ?? "";
        },
        introduction () {
            return this.user?.profile?.introduction ?? "NA";
        },
        referrer () {
            return this.user?.profile?.referrer ?? "";
        },
        account () {
            if (
                this.user &&
                this.user.countryCode &&
                this.user.phone
            ) {
                if (this.$store.state.whatsapp.accountsByUser[`${ this.user.countryCode }${ this.user.phone }`] === undefined) {
                    this.getAccountByUserId();
                }
                return this.$store.state.whatsapp.accountsByUser[`${ this.user.countryCode }${ this.user.phone }`];
            }
            return {};
        },
        chats () {
            return this.account && Array.isArray(this.account.chats) ?
                this.account.chats :
                [];
        },
        verified () {
            return this.user.verified;
        },
        inRequiredGroups () {
            return this.chats
                .reduce(
                    (acc, { tracking }) => acc || tracking,
                    false
                );
        },
    },
    methods: {
        async getAccountByUserId () {
            if (!this.isLoading) {
                this.isLoading = true;
                await this.$store.dispatch(
                    "whatsapp/loadAccount",
                    `${ this.user.countryCode }${ this.user.phone }`
                );
                this.isLoading = false;
            }
        },
        openDeleteUserModal () {
            this.isDeleteUserModalOpened = true;
        },
        async deleteUser () {
            this.isEditing = true;
            try {
                await this.$store.dispatch(
                    "users/deleteUser",
                    this.user._id
                );
            } catch (e) {
                console.error(e);
                this.isEditing = false;
                return;
            }
            this.isEditing = false;
            this.$router.push("/users");
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;

    background-color: rgba(255, 255, 255, 1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    z-index: 3;
}

.actions {
    bottom: 0;
    z-index: 3;
}

.row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.gender-card {
    &.male {
        background-color: #2766f6;

        .logo {
            color: #0843c8;
        }
    }

    &.female {
        background-color: #fc363b;

        .logo {
            color: #e20309;
        }
    }
}

.age-group-card {
    background-color: rgba(255, 132, 51, 1);

    .logo {
        color: rgba(230, 91, 0, 1);
    }
}

.location-card {
    background-color: rgba(106, 110, 115, 1);

    .logo {
        color: rgba(69, 72, 75, 1);
    }
}

.intro-card {
    background-color: rgba(110, 168, 254, 1);

    .logo {
        color: rgba(13, 110, 253, 1);
    }

    .introduction {
        white-space: pre-line;
    }
}

.referrer-card {
    background-color: rgba(23, 162, 184, 1);

    .logo {
        color: rgba(15, 102, 116, 1);
    }
}
</style>
