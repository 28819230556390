<template>
    <div class="card whatsapp-card shadow-sm border-0 rounded-xl overflow-hidden">
        <a class="position-relative content text-white d-flex flex-row"
           v-on:click="isWhatsAppPanelOpen = !isWhatsAppPanelOpen">
            <div class="whatsapp-brand position-absolute h-1 display-4 mb-0 mr-3">
                <font-awesome-icon v-bind:icon="['fab', 'whatsapp']"></font-awesome-icon>
            </div>
            <div class="d-flex justify-content-center align-items-center pl-2 py-2 pr-3">
                <img class="rounded-circle"
                     v-bind:alt="name"
                     v-bind:src="`${ apiUrl }/${ apiVersion }/contact/${ countryCode }${ phone }/avatar`">
            </div>
            <div class="card-body pl-0 py-3 pr-2">
                <h5 class="font-weight-bolder mb-1">
                    {{ account ? account.pushname ? account.pushname : account.formattedName : `+${ countryCode } ${ phone }` }}
                </h5>
                <p class="mb-0">
                    <span class="mr-2">{{ countryCode }}{{ phone }}@c.us</span>
                    <span class="badge badge-secondary">
                        {{ chats.length }}
                    </span>
                </p>
            </div>
            <div class="d-flex align-items-center p-3"
                 v-if="chats.length > 0">
                <font-awesome-icon class="icon"
                                   v-bind:class="{ 'rotated': isWhatsAppPanelOpen }"
                                   v-bind:icon="['far', 'chevron-down']">
                </font-awesome-icon>
            </div>
        </a>
        <b-collapse class="whatsapp-chat-groups"
                    v-model="isWhatsAppPanelOpen">
            <template v-if="account">
                <p class="mb-0 py-3 text-center"
                   v-if="chats.length === 0">
                    No chat group joined
                </p>
                <ul class="list-group list-group-flush"
                    v-else>
                    <li class="list-group-item"
                        v-for="{ _id, contact, metadata, name, profilePic, tracking, lastMessage } in chats"
                        v-bind:key="_id"
                        v-bind:class="{ tracking }">
                        <h5 class="mb-1">
                            {{ name }}
                        </h5>
                        <p class="mb-0">
                            <span v-if="!lastMessage">No activity recorded</span>
                            <span v-else>Last active <strong>{{ lastMessage.timestamp | formatLastActive }}</strong></span>
                        </p>
                    </li>
                </ul>
            </template>
        </b-collapse>
    </div>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config";
import { formatDistanceToNow, parseISO, } from "date-fns";
import { BCollapse } from "bootstrap-vue";
export default {
    name: "WhatsAppCard",
    components: {
        BCollapse,
    },
    filters: {
        formatLastActive (value) {
            if (!value) {
                return "";
            }
            value = value.toString();
            return formatDistanceToNow(
                parseISO(value),
                {
                    "addSuffix": true
                }
            );
        }
    },
    props: {
        "name": {
            type: String,
            required: true,
        },
        "countryCode": {
            type: String,
            required: true,
        },
        "phone": {
            type: String,
            required: true,
        },
        "account": {
            type: Object,
        },
    },
    data () {
        return {
            apiUrl, apiVersion,
            isLoading: false,
            isWhatsAppPanelOpen: true,
        };
    },
    computed: {
        chats () {
            return this.account && Array.isArray(this.account.chats) ?
                this.account.chats :
                [];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.whatsapp-card {
    background-color: #00bfa5;

    .content {
        cursor: pointer;
        overflow: hidden;

        & > .whatsapp-brand {
            bottom: -20px;
            right: 30px;

            font-size: 8rem;
            color: rgba(0, 115, 99, 1);

            transform: rotate(5deg);
            z-index: 0;
        }

        .card-body {
            z-index: 1;
        }

        .rounded-circle {
            width: 64px;
            height: 64px;
            object-fit: cover;
        }

        .icon {
            transition: transform 250ms ease-in-out;

            &.rotated {
                transform: rotate(180deg);
            }
        }
    }

    .whatsapp-chat-groups {
        background-image: url("../../../assets/whatsapp_bg.png");
        background-repeat: repeat;

        .list-group-item {
            position: relative;
            background-color: transparent;

            &.tracking {
                &:after {
                    content: "";

                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;

                    width: 5px;
                    background-color: yellow;
                }
            }
        }
    }
}
</style>
