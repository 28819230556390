<template>
    <div class="position-relative info-card card shadow-sm border-0 rounded-xl overflow-hidden">
        <div class="logo position-absolute h-1 display-4 mb-0 mr-3">
            <slot name="logo"></slot>
        </div>
        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InfoCard",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    .info-card {
        .logo  {
            bottom: -10px;
            right: 0;

            font-size: 7rem;

            transform: rotate(5deg);
            z-index: 0;
        }

        .card-body {
            z-index: 1;
        }
    }
</style>
