<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             content-class="border-0 rounded-xl shadow"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="position-sticky header d-flex justify-content-between align-items-center bg-white pb-1 mb-3">
            <h1 class="font-weight-bolder mb-0">
                {{ user ? `Updating ${ user.role } "${ user.name }"` : "Creating user" }}
            </h1>
            <button class="btn btn-lg btn-outline-dark border-0"
                    v-on:click="hideModal">
                <font-awesome-icon icon="xmark"></font-awesome-icon>
            </button>
        </div>
        <form v-on:submit.prevent.stop="submit">
            <div class="form-row">
                <b-form-group class="col-md-6"
                              label-for="verification">
                    <template v-slot:label>
                        ✔️ Verification
                    </template>
                    <div id="verification"
                         class="btn-group">
                        <button type="button"
                                class="btn border-right-0 shadow-sm"
                                v-bind:class="{ 'btn-success': verified, 'btn-outline-success': !verified, 'font-weight-bolder': verified }"
                                v-on:click="verified = true">
                            🟢 Verified
                        </button>
                        <button type="button"
                                class="btn border-left-0 shadow-sm"
                                v-bind:class="{ 'btn-danger': !verified, 'btn-outline-danger': verified, 'font-weight-bolder': !verified }"
                                v-on:click="verified = false">
                            🔴 Pending
                        </button>
                    </div>
                </b-form-group>

                <b-form-group class="col-md-6"
                              label-for="status">
                    <template v-slot:label>
                        🏵️ Approval Status
                    </template>
                    <div id="status"
                         class="btn-group">
                        <button type="button"
                                class="btn border-right-0 shadow-sm"
                                v-bind:class="{ 'btn-success': approved, 'btn-outline-success': !approved, 'font-weight-bolder': approved }"
                                v-on:click="approved = true">
                            🟢 Approved
                        </button>
                        <button type="button"
                                class="btn border-left-0 shadow-sm"
                                v-bind:class="{ 'btn-danger': !approved, 'btn-outline-danger': approved, 'font-weight-bolder': !approved }"
                                v-on:click="approved = false">
                            🟡 Pending
                        </button>
                    </div>
                </b-form-group>
            </div>

            <div class="form-row">
                <b-form-group class="col-md-6"
                              label-for="role">
                    <template v-slot:label>
                        🤵 User Role
                    </template>
                    <b-form-select id="role"
                                   class="bg-light rounded-pill"
                                   required
                                   v-model="role">
                        <b-form-select-option v-for="value in roles"
                                              v-bind:key="value"
                                              v-bind:value="value">
                            {{ value.charAt(0).toUpperCase() + value.slice(1) }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </div>

            <div class="form-row">
                <b-form-group class="col-md-6"
                              label-for="country-code">
                    <template v-slot:label>
                        🌐 Country Code
                    </template>
                    <b-form-select id="country-code"
                                   class="bg-light rounded-pill"
                                   autocomplete="off"
                                   required
                                   v-model="countryCode">
                        <b-form-select-option v-for="{ value, text } in CountryCodes"
                                              v-bind:key="value"
                                              v-bind:value="value">
                            {{ text }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>

                <b-form-group class="col-md-6"
                              label-for="phone">
                    <template v-slot:label>
                        📱 Phone
                    </template>
                    <b-form-input id="phone"
                                  class="bg-light rounded-pill"
                                  autocomplete="off"
                                  type="text"
                                  required
                                  v-model="phone">
                    </b-form-input>
                </b-form-group>

                <!-- <b-form-group class="col-md-6"
                              label-for="password"
                              label="Password">
                    <b-input-group>
                        <b-form-input id="password"
                                      class="rounded-0"
                                      v-bind:type="passwordInputFocused ? 'text' : 'password'"
                                      required
                                      v-model="password"
                                      v-on:focus="passwordInputFocused = true"
                                      v-on:blur="passwordInputFocused = false">
                        </b-form-input>
                        <b-input-group-append>
                            <button class="btn btn-dark rounded-0"
                                    v-on:click="generateRandomPassword">
                                Generate
                            </button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group> -->
            </div>

            <div class="form-row">
                <b-form-group class="col-md-6"
                              label-for="name">
                    <template v-slot:label>
                        📛 Name
                    </template>
                    <b-form-input id="name"
                                  class="bg-light rounded-pill"
                                  autocomplete="off"
                                  type="text"
                                  required
                                  v-model="name">
                    </b-form-input>
                </b-form-group>
            </div>

            <div class="form-row">
                <b-form-group class="col-md-6"
                              label-for="gender">
                    <template v-slot:label>
                        ♂️♀️ Gender
                    </template>
                    <b-form-select id="gender"
                                   class="bg-light rounded-pill"
                                   required
                                   v-model="gender">
                        <b-form-select-option value="male">
                            ♂️ Male
                        </b-form-select-option>
                        <b-form-select-option value="female">
                            ♀️ Female
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <b-form-group class="col-md-6"
                              label-for="age-group">
                    <template v-slot:label>
                        🗓️ Age Group
                    </template>
                    <b-form-select id="age-group"
                                   class="bg-light rounded-pill"
                                   required
                                   v-model="ageGroup">
                        <b-form-select-option v-for="{ value, text } in AgeGroups"
                                              v-bind:key="text"
                                              v-bind:value="value">
                            {{ text }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>

                <b-form-group class="col-md-12"
                              label-for="introduction">
                    <template v-slot:label>
                        📃 Intro
                    </template>
                    <b-form-textarea id="introduction"
                                     class="bg-light rounded-xl"
                                     autocomplete="off"
                                     rows="4"
                                     v-model="introduction">
                    </b-form-textarea>
                </b-form-group>
            </div>

            <div class="card rounded-xl mb-3"
                 v-if="role === 'organizer'">
                <div class="card-body p-3">
                    <h1 class="h5 card-title">
                        For Organizers
                    </h1>
                    <div class="form-row">
                        <b-form-group class="col-md-6"
                                      label-for="nickname">
                            <template v-slot:label>
                                📛 Nickname
                            </template>
                            <b-form-input id="nickname"
                                          class="bg-light rounded-pill"
                                          autocomplete="off"
                                          type="text"
                                          required
                                          v-model="nickname">
                            </b-form-input>
                        </b-form-group>
                        <b-form-group class="col-md-12"
                                      label-for="active-region">
                            <template v-slot:label>
                                🌏 Active Regions
                            </template>
                            <v-select id="active-region"
                                      class="region-selector form-control bg-light rounded-pill py-0"
                                      multiple
                                      v-bind:options="regions"
                                      v-bind:reduce="({ code }) => code"
                                      v-bind:get-option-label="({ name, code, isActive }) => `${ name } (${ code }) ${ isActive ? '🟢' : '🔴' }`"
                                      v-model="activeRegions">
                            </v-select>
                        </b-form-group>
                        <b-form-group class="col-md-12"
                                      label-for="organizer-introduction">
                            <template v-slot:label>
                                📃 Organizer's Intro
                            </template>
                            <b-form-textarea id="organizer-introduction"
                                             class="bg-light rounded-xl"
                                             autocomplete="off"
                                             rows="4"
                                             v-model="organizerIntroduction">
                            </b-form-textarea>
                        </b-form-group>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <button class="btn btn-primary rounded-pill px-4 mr-2"
                        type="submit"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    💾 Save
                </button>
                <button class="btn btn-secondary rounded-pill px-4"
                        type="reset"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    ♻️ Reset
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { CountryCodes, AgeGroups, } from "@/config";
import { BModal, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BFormSelectOption, } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
    name: "ModalUser",
    components: {
        BModal, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BFormSelectOption,
        vSelect,
    },
    props: {
        "value": {
            type: Boolean,
        },
        "user": {
            type: Object,
        },
    },
    data () {
        return {
            isLoading: false,

            roles: [
                "user",
                "organizer",
                "admin"
            ],
            CountryCodes,
            AgeGroups,

            verified: false,
            approved: false,
            role: "user",

            countryCode: "",
            phone: "",
            name: "",
            nickname: "",

            introduction: "",
            gender: "",
            ageGroup: "",
            origin: "",
            referrer: "",
            snsFbIg: "",

            isTertiary: false,
            joinTertiaryGroup: false,
            becomeOrganizer: false,

            organizerIntroduction: "",
            activeRegions: ["HKG"],
        };
    },
    computed: {
        regions () {
            return this.$store.getters["region/items"];
        },
    },
    methods: {
        reset () {
            this.verified = false;
            this.approved = false;
            this.role = "user";
            this.name = "";
            this.nickname = "";

            this.introduction = "";
            this.gender = "";
            this.ageGroup = "";
            this.origin = "";
            this.referrer = "";
            this.snsFbIg = "";

            this.isTertiary = false;
            this.joinTertiaryGroup = false;
            this.becomeOrganizer = false;
            this.organizerIntroduction = "";

            this.activeRegions = ["HKG"];
        },
        hideModal () {
            this.$emit("input", false);
        },
        generateRandomPassword () {
            this.password = Math.random().toString(36).substr(2);
        },
        submit () {
            if (this.user) {
                this.editUser();
                return;
            }
            this.addUser();
        },
        async addUser () {
            this.isLoading = true;
            const {
                verified, approved,
                role, name,
                countryCode, phone,
                nickname, introduction,
                gender, ageGroup, origin, referrer, snsFbIg, isTertiary, joinTertiaryGroup, becomeOrganizer,
                organizerIntroduction,
                activeRegions,
            } = this;
            const payload = {
                verified, approved,
                role, name,
                countryCode, phone,
                "profile": {
                    nickname, introduction,
                    gender, ageGroup, origin, referrer, snsFbIg, isTertiary, joinTertiaryGroup, becomeOrganizer,
                    organizerIntroduction,
                },
                activeRegions,
            };
            try {
                await this.$store.dispatch("users/addUser", payload);
                this.reset();
                this.hideModal();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },

        async editUser () {
            this.isLoading = true;
            const {
                user: { _id, },
                verified, approved,
                role, name,
                countryCode, phone,
                nickname, introduction,
                gender, ageGroup, origin, referrer, snsFbIg, isTertiary, joinTertiaryGroup, becomeOrganizer,
                organizerIntroduction,
                activeRegions,
            } = this;
            const payload = {
                _id,
                verified, approved,
                role, name,
                countryCode, phone,
                "profile": {
                    nickname, introduction,
                    gender, ageGroup, origin, referrer, snsFbIg, isTertiary, joinTertiaryGroup, becomeOrganizer,
                    organizerIntroduction,
                },
                activeRegions,
            };
            try {
                await this.$store.dispatch("users/editUser", payload);
                this.hideModal();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted () {
        if (this.user) {
            this.verified = this.user.verified;
            this.approved = this.user.approved;
            this.role = this.user.role;

            this.countryCode = this.user.countryCode;
            this.phone = this.user.phone;
            this.name = this.user.name;
            this.nickname = this.user.profile?.nickname ?? "";
            this.gender = this.user.profile.gender;
            this.ageGroup = this.user.profile.ageGroup;
            this.introduction = this.user.profile?.introduction ?? "";

            this.origin = this.user.profile?.origin ?? "";
            this.referrer = this.user.profile?.referrer ?? "";
            this.snsFbIg = this.user.profile?.snsFbIg ?? "";

            this.isTertiary = this.user.profile.isTertiary;
            this.joinTertiaryGroup = this.user.profile.joinTertiaryGroup;
            this.becomeOrganizer = this.user.profile.becomeOrganizer;

            this.organizerIntroduction = this.user.profile?.organizerIntroduction ?? "";

            this.activeRegions = this.user.activeRegions ?? ["HKG"];
        }
    },
    watch: {
        "user" (newVal) {
            if (newVal) {
                this.verified = newVal.verified;
                this.approved = newVal.approved;
                this.role = newVal.role;

                this.countryCode = newVal.countryCode;
                this.phone = newVal.phone;
                this.name = newVal.name;
                this.nickname = newVal.profile?.nickname ?? "";
                this.gender = newVal.profile.gender;
                this.ageGroup = newVal.profile.ageGroup;
                this.introduction = newVal.profile?.introduction ?? "";

                this.origin = newVal.profile?.origin ?? "";
                this.referrer = newVal.profile?.referrer ?? "";
                this.snsFbIg = newVal.profile?.snsFbIg ?? "";

                this.isTertiary = newVal.profile.isTertiary;
                this.joinTertiaryGroup = newVal.profile.joinTertiaryGroup;
                this.becomeOrganizer = newVal.profile.becomeOrganizer;

                this.organizerIntroduction = newVal.profile?.organizerIntroduction ?? "";

                this.activeRegions = newVal.activeRegions ?? ["HKG"];
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~vue-select/src/scss/vue-select";

.header {
    top: 0;
    z-index: 1;
}

.btn-group {
    button {
        &:first-child {
            border-radius: 50rem;
        }

        &:last-child {
            border-radius: 50rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
</style>

<style lang="scss">
.region-selector {
    .vs__dropdown-toggle {
        height: 100%;
        border: none;
    }
}
</style>
